import React from "react"
import styles from "./hero.module.css"

const Hero = props => (
  <div
    className={
      styles.hero + " " + (props.heroleft ? styles.heroleft : styles.herocenter)
    }
  >
    <h1 style={{ marginBottom: `0px` }}>{props.hero}</h1>
    <h2>{props.herosub}</h2>
  </div>
)

export default Hero
